
<div>
    <button type="button" class="btn button-1 unlogged align-items-center btn-secondary" @click.prevent="openModal">
        <img alt="" class="navbar-icon d-none d-md-flex" src="/public-assets/user-icon"/>
        <span class="d-flex d-md-none">Sign in</span>
        <span class="d-none d-md-flex">Login</span>
        <span class="d-none d-md-flex">  &nbsp;/&nbsp;Register</span>
    </button>
    <LoginModal ref="loginModal" @registerModal="registerModal"/>
    <RegisterModal ref="registerModal" @registerModal="registerModal"/>
</div>
