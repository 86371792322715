<template>
    <Modal v-if="showModal" id="modal-1" modalSize="md" @close="hide">
        <template v-slot:header>
            <h5 class="modal-title">Log in / Register</h5>
        </template>

        <template v-slot:body>
            <div v-if="step === 1">
                <p class="modal-txt-1 mt-4">Enter your email for your login or register</p>
                <input type="text" name="email" data-vv-as="email" placeholder="Insert email here" class="modal-input w-100" v-model="email" v-validate="'required|email'">
                <span class="error">{{ errors.first('email') }}</span>

                <div class="d-flex justify-content-end pt-3 w-100">
                    <!-- Button with custom close trigger value -->
                    <button type="button"
                            v-if="step === 1"
                            class="btn button-2 btn-secondary"
                            :class="{'disabled': !email || !!errors.first('email') }"
                            :disabled="!email || !!errors.first('email') || loading"
                            @click.prevent="submit">
                      <div class="d-flex align-items-center justify-content-center">
                        <span class="mx-3">Continue</span>
                        <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                    </button>

                    <div v-else class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
            </div>
            <div v-if="step === 2" id="send" class="send-email">
                <div style="flex:1;"></div>
                <h5 class="h5-email d-flex justify-content-center align-items-center">NSC has sent you an email to
                    {{email}}.
                </h5>
                <p class="p-email">Check your inbox and click on the link to get access.</p>
                <p class="p-email">Please allow for two minutes or try checking your SPAM folder.</p>
                <span class="link-email"><a id="options" @click="submit" >Send email again</a> or <a @click="email = '', step = 1" id="options" >Enter your email again</a></span>
            </div>
        </template>

        <template v-slot:footer>
            <div class="d-flex d-none justify-content-end w-50"></div>
        </template>
    </Modal>
</template>
<script>

import Modal from '@/components/cali-modal/App.vue';

export default {
    components: { Modal },
    $_veeValidate: {
        validator: 'new' // give me my own validator scope.
    },
    data: () => ({
        email: null,
        loading: false,
        step: 1,
        showModal: false,
    }),
    methods: {
        show() {
            this.showModal = true;
        },

        hide() {
            this.showModal = false;
        },

        submit(callback) {
            if (this.email != null && this.errors.items.length == 0) {
                this.loading = true;
                this.$axios.post('/api/verify-email', {
                    data: {
                        email: this.email
                    }
                }).then(response => {
                    if(response.data.status === 404) {
                        this.$emit('registerModal', true, this.email, callback);
                    } else if(response.data.status === 200) {
                        this.sendMagicLink();
                        this.$swal('Magic link sent','We\'ve sent a Magic Link to the email address entered with instructions to login.','success');
                        this.step = 2;
                        this.loading = false;
                        callback
                    } else {
                        this.$swal('Oops..','Something was wrong','error');
                    }
                }).catch(error => {
                    this.$swal('Oops..','Something was wrong','error');
                }).finally(response => {
                    this.loading = false;
                })
            }
        },

        sendMagicLink() {
            let data = {
                email: this.email,
                redirect: '/'
            }

            this.$axios.post('/api/send-magic-link',{
                data: data
            })
        }
    }
}
</script>
<style lang="scss" scoped >
.button-2{
    width: 200px;
    text-align: center;
}
.send-email{
    text-align: center;
    margin: 0 auto;
    margin-top: 20px;
}
.h5-email{
    color: #000000;
    font-weight: 600;
}
.p-email{
    color: grey;
    font-weight: 400;
}
.link-email{
    margin-left: 15px;
    color: grey;
}
#options{
    color: #069efc;
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;
    margin-left: 11px;
    margin-right: 11px;
}
.p-email-responsive{
    color: grey;
    font-weight: 400;
}
</style>