
<Modal v-if="showModal" id="modal-1" modalSize="md" @close="hide">
    <template v-slot:header>
        <h5 class="modal-title">Log in / Register</h5>
    </template>

    <template v-slot:body>
        <div v-if="step === 1">
            <p class="modal-txt-1 mt-4">Enter your email for your login or register</p>
            <input type="text" name="email" data-vv-as="email" placeholder="Insert email here" class="modal-input w-100" v-model="email" v-validate="'required|email'">
            <span class="error">{{ errors.first('email') }}</span>

            <div class="d-flex justify-content-end pt-3 w-100">
                <!-- Button with custom close trigger value -->
                <button type="button"
                        v-if="step === 1"
                        class="btn button-2 btn-secondary"
                        :class="{'disabled': !email || !!errors.first('email') }"
                        :disabled="!email || !!errors.first('email') || loading"
                        @click.prevent="submit">
                  <div class="d-flex align-items-center justify-content-center">
                    <span class="mx-3">Continue</span>
                    <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only"></span>
                    </div>
                  </div>
                </button>

                <div v-else class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
        </div>
        <div v-if="step === 2" id="send" class="send-email">
            <div style="flex:1;"></div>
            <h5 class="h5-email d-flex justify-content-center align-items-center">NSC has sent you an email to
                {{email}}.
            </h5>
            <p class="p-email">Check your inbox and click on the link to get access.</p>
            <p class="p-email">Please allow for two minutes or try checking your SPAM folder.</p>
            <span class="link-email"><a id="options" @click="submit" >Send email again</a> or <a @click="email = '', step = 1" id="options" >Enter your email again</a></span>
        </div>
    </template>

    <template v-slot:footer>
        <div class="d-flex d-none justify-content-end w-50"></div>
    </template>
</Modal>
