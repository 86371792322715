<template>
    <Modal v-if="openModal" id="modal-1" modalSize="md" @close="hide">
        <template v-slot:header>
            <h5 class="modal-title">{{ config ? config.form_title : 'Loading...' }}</h5>
        </template>

        <template v-slot:body>
            <div v-if="config">
                <p class="">Please complete the following form:</p>
                <div class="d-flex flex-wrap">
                    <div v-for="(field, index) in config.fields" class="d-flex flex-column pe-3 register-form"
                         :class="has_width_100.includes(field.slug) ? 'w-100' : 'w-50'" :key="index">
                        <template v-if="!['source', 'rfc'].includes(field.slug)">
                            <div v-if="field.slug.includes('address_line_1')" class="w-100 address px-0 pt-0">Address
                            </div>
                            <Field v-model="form_filled[field.slug]"
                                   :field="{...field, ...{disabled: field.slug === 'email'}}" :name="field.slug"
                                   :data-vv-as="field.title"/>
                            <div v-if="field.data_type_enum === 40" class="d-flex flex-column">
                                <label class="form-label">{{ field.title }}</label>
                                <span>{{field.help}}</span>
                                <select v-model="form_filled[field.slug]" class="form-control" :placeholder="field.placeholder">
                                    <option v-for="(option, index) in field.options.split(',')" class="text-align-center" :key="index">{{option}}</option>
                                </select>
                            </div>
                            <span class="error">{{ errors.first(field.slug) }}</span>
                        </template>
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
        </template>

        <template v-slot:footer>
            <!-- Button with custom close trigger value -->
            <button type="button"
                    :disabled="loading"
                    class="btn button-2 btn-secondary px-5"
                    @click.prevent="submit(hide)">
                <div class="d-flex align-items-center justify-content-center">
                    <span class="mx-3">Register</span>
                    <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
            </button>

        </template>
    </Modal>
</template>
<script>
import Vue from 'vue'
import Field from './helpers/Field.vue';
import Modal from '@/components/cali-modal/App.vue';

export default {
    $_veeValidate: {
        validator: 'new' // give me my own validator scope.
    },
    components: {
        Field, Modal
    },
    data: () => ({
        form: null,
        showModal: false,
        has_width_100: ['phone', 'email', 'address_line_1', 'address_line_2', 'city', 'terms_and_conditions', 'rfc', 'default_address', 'alias'],
        form_filled: {},
        loading: false,
        openModal: false
    }),
    mounted() {
        Vue.nextTick(() => {
            // validators will be paused until the user hits 'OK' the first time
            this.$validator.pause();

            this.$axios.get('/api/form/register').then(response => {
                // Find struct field and replace with their fields
                let temp_form = response.data.data;

                if (temp_form) {
                    // let structIndex = temp_form.config_json.fields.findIndex(x => x.data_type_enum === 95);
                    // let structFields = temp_form.config_json.fields[structIndex].fields.map(field => {
                    //     if (!field.help.includes('*') && field.data_type_enum !== 21) {
                    //         field.help = '*' + field.help
                    //     }
                    //     return field
                    // });

                    // temp_form.config_json.fields.splice(structIndex, 1, ...structFields)
                    // With all fields set form in form var
                    this.form = temp_form;

                    if (this.form.config_json && this.form.config_json.fields) {
                        this.form_filled.form_slug = this.form.slug;
                        this.form.config_json.fields.forEach(field => {
                            if (field.slug === 'source') this.$set(this.form_filled, field.slug, 'RegisterForm');
                            else if (field.slug === 'phone') this.$set(this.form_filled, field.slug, {});
                            else if (field.slug !== 'email') this.form_filled[field.slug] = this.$set(this.form_filled, field.slug, '');
                        })

                    }
                }
            })
        });
    },
    methods: {
        show() {
            this.openModal = true;
        },

        hide() {
            this.openModal = false;
        },

        submit(callback) {
            this.$validator.resume();
            this.$validator.validate().then(value => {
                if (!value) {
                    this.$swal('Error..', 'Fill all the required fields', 'error');
                    return
                }
                this.loading = true;

                this.$axios.post('/api/form/register', {
                    data: {
                        form_slug: this.form_filled.form_slug,
                        given_name: this.form_filled.given_name,
                        last_name: this.form_filled.last_name,
                        email: this.form_filled.email,
                        phone: this.form_filled.phone,
                        venue: this.form_filled.venue,
                        role: this.form_filled.role,
                        struct_address: [{
                            // state: this.form_filled.state,
                            // address_line_1: this.form_filled.address_line_1,
                            // address_line_2: this.form_filled.address_line_2,
                            // city: this.form_filled.city,
                            // zip_code: this.form_filled.zip_code,
                            // default_address: this.form_filled.default_address,
                            // alias: this.form_filled.alias,
                        }],
                        terms_and_conditions: this.form_filled['terms_and_conditions'],
                        source: this.form_filled.source,
                        rfc: '1234567890123'
                    }
                }).then(() => {
                    this.sendMagicLink(callback);
                }).catch(() => {
                    this.$swal('Oops..', 'Something was wrong', 'error');
                }).finally(() => {
                    this.loading = false;
                })
            });
        },
        addData(data) {
            this.form_filled = Object.assign(this.form_filled, data)
        },
        sendMagicLink(callback) {
            this.$axios.post('/api/send-magic-link', {
                data: {
                    email: this.form_filled.email,
                    redirect: '/',
                    template: 'register'
                }
            }).then(() => {
                this.$swal('You have been successfully registered!', 'Nightlife Security Consultants has sent you an email. Please, check you email to get access. Don’t forget checking your spam folder', 'success');
                callback('continue')
            })
        }
    },
    computed: {
        config: function () {
            return this.form && this.form.config_json ? this.form.config_json : null
        }
    },
    watch: {
        showModal: {
            handler: function (newValue) {
                if (!newValue) {
                    this.$emit('registerModal')
                }
            },
            immediate: false
        }
    }
}
</script>