
<transition name="modal">
  <div class="cali__modal-mask" role="dialog">
    <div class="cali__modal-dialog mb-4" role="document">
      <div class="cali__modal-content" :class="('size-' + modalSize)" :id="uniqueId">

        <div class="cali__modal-header">
          <slot name="header">
            <h4 class="cali__modal-title">{{ title || 'Default title' }}</h4>
          </slot>

          <a class="cali_modal-btn-close" @click.prevent="$emit('close')">
            <img src="/public-assets/close" class="modal-close-icon img-fluid" alt="close modal">
          </a>
        </div>

        <div class="cali__modal-body">
          <slot name="body">
            Default body
          </slot>
        </div>

        <div class="cali__modal-footer">
          <slot name="footer">
            <button type="button" class="cali__modal-btn-primary" @click="$emit('close')">OK</button>
            <button type="button" class="cali__modal-btn-secondary" @click="$emit('close')"><i class="fal fa-times"></i> Close</button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</transition>
