
<Modal v-if="openModal" id="modal-1" modalSize="md" @close="hide">
    <template v-slot:header>
        <h5 class="modal-title">{{ config ? config.form_title : 'Loading...' }}</h5>
    </template>

    <template v-slot:body>
        <div v-if="config">
            <p class="">Please complete the following form:</p>
            <div class="d-flex flex-wrap">
                <div v-for="(field, index) in config.fields" class="d-flex flex-column pe-3 register-form"
                     :class="has_width_100.includes(field.slug) ? 'w-100' : 'w-50'" :key="index">
                    <template v-if="!['source', 'rfc'].includes(field.slug)">
                        <div v-if="field.slug.includes('address_line_1')" class="w-100 address px-0 pt-0">Address
                        </div>
                        <Field v-model="form_filled[field.slug]"
                               :field="{...field, ...{disabled: field.slug === 'email'}}" :name="field.slug"
                               :data-vv-as="field.title"/>
                        <div v-if="field.data_type_enum === 40" class="d-flex flex-column">
                            <label class="form-label">{{ field.title }}</label>
                            <span>{{field.help}}</span>
                            <select v-model="form_filled[field.slug]" class="form-control" :placeholder="field.placeholder">
                                <option v-for="(option, index) in field.options.split(',')" class="text-align-center" :key="index">{{option}}</option>
                            </select>
                        </div>
                        <span class="error">{{ errors.first(field.slug) }}</span>
                    </template>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only"></span>
            </div>
        </div>
    </template>

    <template v-slot:footer>
        <!-- Button with custom close trigger value -->
        <button type="button"
                :disabled="loading"
                class="btn button-2 btn-secondary px-5"
                @click.prevent="submit(hide)">
            <div class="d-flex align-items-center justify-content-center">
                <span class="mx-3">Register</span>
                <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
        </button>

    </template>
</Modal>
