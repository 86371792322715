<template>
    <div>
        <button type="button" class="btn button-1 unlogged align-items-center btn-secondary" @click.prevent="openModal">
            <img alt="" class="navbar-icon d-none d-md-flex" src="/public-assets/user-icon"/>
            <span class="d-flex d-md-none">Sign in</span>
            <span class="d-none d-md-flex">Login</span>
            <span class="d-none d-md-flex">  &nbsp;/&nbsp;Register</span>
        </button>
        <LoginModal ref="loginModal" @registerModal="registerModal"/>
        <RegisterModal ref="registerModal" @registerModal="registerModal"/>
    </div>
</template>
<script>
import LoginModal from './LoginModal.vue';
import RegisterModal from './RegisterModal.vue';

export default {
    props: {
        contact: {
            type: Object
        }
    },
    components: {
        LoginModal, RegisterModal
    },
    computed: {
        shortName() {
            return (this.contact.given_name[0] + this.contact.last_name[0]).toUpperCase();
        }
    },
    data: () => ({
        register_modal_open: false,
        status: {
            get: false,
            created: false
        },
        visible: false,
    }),
    methods: {
        openModal() {
            this.$refs.loginModal.show()
        },
        registerModal(value = false, email = null, callback = null) {
            this.register_modal_open = value;
            setTimeout(() => {
                if (this.register_modal_open) {
                    callback
                    this.$refs.loginModal.hide()
                    this.$refs.registerModal.show();
                    this.$refs.registerModal.addData({email: email});
                }
            }, 50)
        },
        openSidebarCart() {
            if (window.location.href.includes('checkout')) {
                window.location.href = '/checkout';
            } else {
                this.visible = true;
            }
        }
    }
}
</script>